<div
  (ccResize)="handleContainerResize($event)"
  class="tasks-list"
>
  <ng-scrollbar
    (scrolled)="loadNextTasks()"
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="0"
    [infiniteScrollUpDistance]="4"
    [scrollWindow]="false"
    cdkScrollable
    infiniteScroll
    class="tasks-list__list"
  >
    <div class="tasks-list__list-wrapper">
      <cc-tasks-header class="tasks-list__list-wrapper-header" />
      <cc-tasks-table class="tasks-list__list-wrapper-list" />
    </div>
  </ng-scrollbar>

  <div
    (nzResize)="resizeTaskDrawer($event)"
    [class.tasks-list__drawer--hidden]="!drawerOutlet.isActivated"
    [class.tasks-list__drawer--overlay]="drawerOverlayMode()"
    [nzDisabled]="!drawerOutlet.isActivated"
    [nzMaxWidth]="taskDrawerMaxWidth()"
    [nzMinWidth]="taskDrawerMinWidth"
    [style.width.px]="taskDrawerWidth()"
    class="tasks-list__drawer"
    nz-resizable
  >
    <router-outlet #drawerOutlet="outlet" />

    <nz-resize-handle
      (dblclick)="resetTaskDrawerWidth()"
      class="tasks-list__drawer-resize-handle"
      nzDirection="left"
    >
      <div class="tasks-list__drawer-resize-handle-line"></div>
    </nz-resize-handle>
  </div>
</div>
