import { inject, Injector } from '@angular/core';
import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import * as R from 'ramda';

import { FilterableTaskStatus, TaskType } from '@clover/conversations-v4/tasks/tasks.model';
import type { UserPermissions } from '@clover/core/services/permission.service';
import { UserService } from '@clover/core/services/user.service';

import { TasksSelectors } from './state/tasks.selectors';

export enum TaskTypeRouteParam {
  Received = 'received',
  Sent = 'sent',
}

export enum TaskStatusRouteParam {
  All = 'all',
  Incomplete = 'incomplete',
  Completed = 'completed',
  Closed = 'closed',
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  NotConnected = 'not-connected',
  WaitingForApproval = 'waiting-for-approval',
}

export const taskTypeRoutesMap = new Map<TaskTypeRouteParam, TaskType>([
  [TaskTypeRouteParam.Received, TaskType.Received],
  [TaskTypeRouteParam.Sent, TaskType.Sent],
]);

export const taskStatusRoutesMap = new Map<TaskStatusRouteParam, FilterableTaskStatus>([
  [TaskStatusRouteParam.All, FilterableTaskStatus.All],
  [TaskStatusRouteParam.Incomplete, FilterableTaskStatus.Incomplete],
  [TaskStatusRouteParam.Completed, FilterableTaskStatus.Completed],
  [TaskStatusRouteParam.Closed, FilterableTaskStatus.Closed],
  [TaskStatusRouteParam.NotStarted, FilterableTaskStatus.NotStarted],
  [TaskStatusRouteParam.InProgress, FilterableTaskStatus.InProgress],
  [TaskStatusRouteParam.NotConnected, FilterableTaskStatus.NotConnected],
  [TaskStatusRouteParam.WaitingForApproval, FilterableTaskStatus.WaitingForApproval],
]);

export const defaultTaskType: TaskType = TaskType.Received;
export const defaultTaskStatus: FilterableTaskStatus = FilterableTaskStatus.Incomplete;

export const tasksListGuard: CanActivateFn = (route) => {
  const {
    queryParams: { query: queryParam, type: typeParam, status: statusParam },
  } = route;

  const injector = inject(Injector);

  const [queryValid, query] = validateQueryParam(queryParam, injector);
  const [typeValid, type] = validateTypeParam(typeParam, injector.get(UserService).permissions);
  const [statusValid, status] = validateStatusParam(statusParam, injector);

  if (!typeValid && !type) return false; // If type is not valid and not defined, do not allow the route
  if (queryValid && typeValid && statusValid) return true;

  const segmentParams = {
    query,
    type,
    status,
  };

  // Remove undefined parameters
  const definedParams = R.pickBy((value) => !!value, segmentParams);

  return createUrlTreeFromSnapshot(route, [], definedParams);
};

function validateQueryParam(param: string | undefined, injector: Injector): [boolean, string] {
  return [true, param || injector.get(Store).selectSnapshot(TasksSelectors.tasksQuery)];
}

function validateTypeParam(
  param: TaskTypeRouteParam | undefined,
  permissions: UserPermissions,
): [boolean, string | undefined] {
  // Check for type validity
  if (!taskTypeRoutesMap.has(param)) return [false, taskTypeToRouteParam(defaultTaskType)];

  const canViewReceivedTab = permissions.Task_ViewAssigned;
  const canViewSentTab = permissions.Task_Assign;

  // If Sent tasks are not allowed, fallback to Received (if allowed)
  if (param === TaskTypeRouteParam.Sent && !canViewSentTab && canViewReceivedTab)
    return [false, taskTypeToRouteParam(TaskType.Received)];

  // If Received tasks are not allowed, fallback to Sent (if allowed)
  if (param === TaskTypeRouteParam.Received && !canViewReceivedTab && canViewSentTab)
    return [false, taskTypeToRouteParam(TaskType.Sent)];

  // If both tabs are not allowed, do not allow the route
  if (!canViewReceivedTab && !canViewSentTab) return [false, undefined];

  return [true, param];
}

function validateStatusParam(param: TaskStatusRouteParam | undefined, injector: Injector): [boolean, string] {
  const valid = taskStatusRoutesMap.has(param);
  return [
    valid,
    valid ? param : taskStatusToRouteParam(injector.get(Store).selectSnapshot(TasksSelectors.tasksStatus)),
  ];
}

export function taskTypeToRouteParam(type: TaskType): TaskTypeRouteParam {
  for (const [param, value] of taskTypeRoutesMap) {
    if (value === type) return param;
  }

  return taskTypeToRouteParam(defaultTaskType);
}

export function taskStatusToRouteParam(status: FilterableTaskStatus): TaskStatusRouteParam {
  for (const [param, value] of taskStatusRoutesMap) {
    if (value === status) return param;
  }

  return taskStatusToRouteParam(defaultTaskStatus);
}
